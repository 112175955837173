import React, { InputHTMLAttributes } from 'react'
import { ReactBaseProps, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'

type ImageBlockProps = InputHTMLAttributes<HTMLImageElement> & ReactBaseProps & ReactMarkdownProps

const ImageBlock = ({ alt, src, onClick }: ImageBlockProps) => {
  return (
    <img alt={alt} src={src} onClick={onClick} />
  )
}

export default ImageBlock
