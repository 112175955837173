// Reference: https://github.com/remarkjs/react-markdown/issues/69
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { ReactBaseProps, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'
import slugify from 'slugify'

function flatten (text: string, child: ReactNode | string): string {
  if (child) {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray((child as ReactElement).props.children).reduce(flatten, text)
  }

  return ''
}

type HeadingBlockProps = {
  setActiveSlug: (value: string) => void;
  scroll: number;
} & ReactBaseProps & ReactMarkdownProps & { level: number }

const HeadingBlock = ({ level, setActiveSlug, scroll, ...props }: HeadingBlockProps) => {
  const ref = useRef<HTMLElement>(null)
  const text = props.children.reduce(flatten, '')
  const slug = slugify(text, { lower: true })

  useEffect(() => {
    if (ref.current) {
      const offsetTop = ref.current?.getBoundingClientRect().top - document.body.getBoundingClientRect().top
      if (offsetTop - 100 <= scroll) {
        setActiveSlug(slug)
      }
    }
  }, [])

  return React.createElement('h' + level, { id: slug, ref }, props.children)
}

export default HeadingBlock
