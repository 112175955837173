import React from 'react'
import { Link } from 'gatsby'
import { ReactBaseProps, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'

type AnchorBlockProps = ReactBaseProps & ReactMarkdownProps & { href?: string }

const AnchorBlock = ({ href, ...props }: AnchorBlockProps) => {
  return (
    (href as string).includes('://')
      ? <a href={href as string} target='_blank' rel='nofollow noopener noreferrer'>{props.children}</a>
      : <Link to={href as string}>{props.children}</Link>
  )
}

export default AnchorBlock
