import React, { ReactNode, MouseEvent } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { createPortal } from 'react-dom'

// Hooks
import useDomReady from 'src/hooks/useDomReady'

// Styles
import { ModalContainer } from './style'

type ModalProps = {
  setVisible: (value: boolean) => void;
  visible: boolean;
  children: ReactNode;
}

const Modal = ({ children, visible, setVisible }: ModalProps) => {
  const domReady = useDomReady()

  function close (evt: MouseEvent<HTMLDivElement | HTMLButtonElement>) {
    if (evt.target === evt.currentTarget) {
      setVisible(false)
    }
  }

  return (
    (domReady && visible) ? (
      createPortal(
        <ModalContainer onClick={close}>
          {children}
          <button onClick={() => setVisible(false)}><IoIosCloseCircleOutline color='white' size={35} /></button>
        </ModalContainer>,
        document.body,
      )
    ) : <></>
  )
}

export default Modal
