import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

function useScrollY (wait: number = 250) {
  const [ scrollY, setScrollY ] = useState(0)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollY(window.pageYOffset)
    }, wait)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return scrollY
}

export default useScrollY
