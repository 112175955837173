import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'
import spaces from 'src/styles/spaces'
import fonts from 'src/styles/fonts'
import colors from 'src/styles/colors'
import breakpoints from 'src/styles/breakpoints'

export const ArticleHeader = styled.header`
  padding: 10rem 0 3.5rem;

  .categories, .info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .categories {

    &__item {
      background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
      margin: 0 ${spaces.xs};
      border-radius: 3px;
      padding: ${spaces.xs} ${spaces.md};
      font-weight: 700;
    }
  }

  .title {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    text-align: center;
    margin: 2rem 2.5rem;
  }

  .info {

    span {
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
      font-size: ${fonts.sm};
      margin: 0 2.1rem;

      @media (min-width: ${breakpoints.lg}) {
        font-size: ${fonts.sm};
      }

      + span {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: -2.1rem;
          height: 6px;
          width: 6px;
          border-radius: 100%;
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
        }
      }
    }
  }
`

type ArticleBannerProps = {
  fluid: FluidObject | FluidObject[];
}

export const ArticleBanner = styled(Img)<ArticleBannerProps>`
  height: 275px;
  width: 100%;
`

export const ArticleContent = styled.article`
  padding: 3rem 0;

  h2, h3, h4, h5 {
    font-weight: 600;
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    display: block;
  }

  h2 {
    font-size: 2.5rem;
    margin-top: 2.5rem;
    margin-bottom: ${spaces.xl};
  }

  h3 {
    font-size: 2rem;
    margin-top: ${spaces.xl};
    margin-bottom: ${spaces.lg};
  }

  h4 {
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: ${spaces.lg};
  }

  h5 {
    font-size: ${fonts.md};
    margin-top: 1.5rem;
    margin-bottom: ${spaces.md};
  }

  p {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
    font-size: ${fonts.md};
    line-height: 2.5rem;
    margin-bottom: ${spaces.lg};
    display: inline-block;

    @media (min-width: ${breakpoints.lg}) {
      font-size: ${fonts.sm};
    line-height: 2rem;
    }
  }

  a {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    cursor: pointer;
  }

  hr {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
  }

  iframe {
    margin-bottom: 2rem;
  }

  blockquote {
    padding: ${spaces.lg} ${spaces.xl};
    border-left: 4px solid ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    margin: 1.5rem 0;

    p {
      margin-bottom: 0;
      font-size: ${fonts.lg};
      line-height: 2.8rem;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: circle;
  }

  ul, ol {
    padding-left: 2.5rem;
    margin-bottom: 2.5rem;

    li {
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
      font-size: ${fonts.sm};
      line-height: 2rem;
      margin-bottom: ${spaces.sm};
    }
  }

  table {
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: ${spaces.xxl};
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

    thead {

      tr {
        border-radius: 1rem;
      }

      th {
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        padding: 1rem;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
        font-weight: 600;
        font-size: ${fonts.xs};
      }
    }

    tbody {
      background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

      tr {

        &:not(:last-child) {
          border-bottom: 1px solid ${colors.gray[200]};
        }
      }

      td {
        padding: 1rem;
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
        font-weight: 500;
        font-size: ${fonts.xs};
      }
    }
  }

  code, pre {
    border-radius: 1.5rem;

    &.inline {
      padding: .2em .4em;
      margin: 0;
      font-size: 85%;
      font-weight: 600;
      border-left: none;
    }

    span {
      font-family: "Roboto Mono", monospace;
      font-size: 1.4rem;
    }
  }
`

export const ArticleSidebar = styled.aside`
  padding: 3rem 0;
  height: 100%;
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`

export const ArticleQuickAccess = styled.div`
  margin: 3rem 0;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
  border-radius: ${spaces.md};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 80px;

  header {
    padding: ${spaces.lg} ${spaces.xl};
    border-bottom: 0.2px solid ${colors.gray[200]};

    p {
      text-transform: uppercase;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      font-size: ${fonts.md};
      font-weight: 600;
    }
  }

  ul {
    padding: ${spaces.lg} ${spaces.xl};

    li {

      &:not(:last-child) {
        margin-bottom: ${spaces.lg};
      }

      a {
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        font-size: ${fonts.sm};
        font-weight: 400;

        &:hover, &.active {
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          font-weight: 500;
        }
      }
    }
  }
`

export const ArticleImageModal = styled.img`

  @media (min-width: ${breakpoints.lg}) {
    max-width: 900px;
  }
`
