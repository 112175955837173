import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FluidObject, GatsbyImageFluidProps } from 'gatsby-image'
import { graphql, PageProps } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { ReactBaseProps, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import Layout from 'src/layout'

// Hooks
import useWindowSize from 'src/hooks/useWindowSize'

// Helpers
import scrollTo from 'src/helpers/scrollTo'

// Components
import SEO from 'src/components/seo'

// Blocks
import CodeBlock from './blocks/CodeBlock'
import HeadingBlock from './blocks/HeadingBlock'

// Styles
import { Col, Container, Row } from 'src/styles/grid'
import { ArticleHeader, ArticleBanner, ArticleContent, ArticleSidebar, ArticleQuickAccess, ArticleImageModal } from './style'
import useScrollY from 'src/hooks/useScrollY'
import AnchorBlock from './blocks/AnchorBlock'
import Modal from 'src/components/Modal'
import ImageBlock from './blocks/ImageBlock'

type Article = {
  title: string;
  description: string;
  content: string;
  publishedAt: string;
  readingTime: string;
  banner: {
    childImageSharp: GatsbyImageFluidProps;
  };
  thumbnail: {
    childImageSharp: GatsbyImageFluidProps;
  };
  category: {
    name: string;
  };
}

type Heading = {
  id: string;
  text: string;
}

type Query = {
  data: {
    strapiArticle: Article;
  };
}

const WIDTH_MD = 768

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      description
      content
      publishedAt
      readingTime
      banner {
        childImageSharp {
          fluid (quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thumbnail {
        childImageSharp {
          fluid (quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      category {
        name
      }
    }
  }
`

const Article = (props: PageProps & Query) => {
  const article = props.data.strapiArticle
  const scroll = useScrollY()
  const windowSize = useWindowSize()
  const [ activeSlug, setActiveSlug ] = useState<string>()
  const [ headings, setHeadings ] = useState<Heading[]>([])
  const [ showModal, setShowModal ] = useState<{ visible: boolean; content?: JSX.Element }>({
    visible: false,
  })
  const minutes = useMemo(() => {
    const [ , minutes ] = article.readingTime.split(':')
    return minutes
  }, [ article.readingTime ])
  const bannerImage = useMemo(() => {
    return windowSize?.width < WIDTH_MD ? article.thumbnail.childImageSharp.fluid : article.banner.childImageSharp.fluid
  }, [ windowSize.width, article.thumbnail, article.banner ])

  const modal = (
    <Modal visible={showModal.visible} setVisible={(visible: boolean) => setShowModal({ ...showModal, visible })}>
      {showModal.content}
    </Modal>
  )

  const handleContentModal = useCallback((source: string, type: string) => {
    let content = <></>
    switch (type) {
      case 'image':
        content = (
          <ArticleImageModal src={source} />
        )
        break
      case 'video':
        content = (
          <iframe
            width='560'
            height='315'
            src={source}
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )
        break
    }

    setShowModal({
      visible: true,
      content,
    })
  }, [])

  useEffect(() => {
    const headingsEl = document.querySelectorAll('h2')
    const headings: Heading[] = []
    headingsEl.forEach(({ id, innerHTML }: Element) => {
      headings.push({
        id: id,
        text: innerHTML,
      })
    })
    setActiveSlug(headings[0].id)
    setHeadings(headings)
  }, [])

  return (
    <Layout>
      {modal}
      <SEO
        title={article.title}
        description={article.description}
        url={props.location.pathname}
        shareImage={(article.thumbnail.childImageSharp.fluid as FluidObject).src}
        type='article'
      />
      <ArticleHeader>
        <Container>
          <Row centered>
            <Col lg={8}>
              <div className='categories'>
                <span className='categories__item'>{article.category.name}</span>
              </div>
              <h1 className='title'>{article.title}</h1>
              <div className='info'>
                <span>{format(parseISO(article.publishedAt), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}</span>
                <span>Leitura de {minutes} min</span>
              </div>
            </Col>
          </Row>
        </Container>
      </ArticleHeader>
      <ArticleBanner fluid={bannerImage} />
      <Container>
        <Row spaceBetween>
          <Col lg={7}>
            <ArticleContent>
              <ReactMarkdown
                rehypePlugins={[ gfm, rehypeRaw ]}
                components={{
                  code: (props: ReactBaseProps & ReactMarkdownProps & { inline?: boolean }) => (
                    <CodeBlock {...props} />
                  ),
                  h2: (props: ReactBaseProps & ReactMarkdownProps & { level: number }) => (
                    <HeadingBlock {...props} scroll={scroll} setActiveSlug={setActiveSlug} />
                  ),
                  a: (props: ReactBaseProps & ReactMarkdownProps) => (
                    <AnchorBlock {...props} />
                  ),
                  img: (props: ReactBaseProps & ReactMarkdownProps) => (
                    <ImageBlock {...props} onClick={(event: MouseEvent<HTMLImageElement>) => handleContentModal(event.currentTarget.src, 'image')} />
                  ),
                }}
              >
                {article.content}
              </ReactMarkdown>
            </ArticleContent>
          </Col>
          <Col lg={4}>
            <ArticleSidebar>
              <ArticleQuickAccess>
                <header>
                  <p>Acesso Rápido</p>
                </header>
                <ul>
                  {
                    headings.map((heading: Heading) => (
                      <li key={heading.id}>
                        <a
                          className={heading.id === activeSlug ? 'active' : ''}
                          href={`#${heading.id}`}
                          onClick={scrollTo}
                        >
                          {heading.text}
                        </a>
                      </li>
                    ))
                  }
                </ul>
              </ArticleQuickAccess>
            </ArticleSidebar>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Article
