import styled from 'styled-components'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 ${spaces.lg};
  background-color: #403F3F99;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  button {
    background-color: transparent;
    border: none;
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1.5rem;

    @media (min-width: ${breakpoints.lg}) {
      top: 2rem;
      right: 3rem;
    }
  }
`
