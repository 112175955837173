import React, { useMemo } from 'react'
import { ReactBaseProps, ReactMarkdownProps } from 'react-markdown/src/ast-to-react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

type CodeBlockProps = ReactBaseProps & ReactMarkdownProps & { inline?: boolean }

function CodeBlock ({ inline, className, ...props }: CodeBlockProps) {
  const language = useMemo(() => className?.split('-')[1], [ className ])

  if (inline) {
    return <code className='inline'>{props.children}</code>
  }

  return (
    <SyntaxHighlighter language={language} style={materialDark}>
      {props.children}
    </SyntaxHighlighter>
  )
}

export default CodeBlock
